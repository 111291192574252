import * as React from "react"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import FilterEvent from "./shared/filter-events";
import DateView from "./shared/date-view";
import EventList from "./event/event-list";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreOutlined, FilterListOutlined } from "@mui/icons-material";

const EditionEvents = ({ events: unsortedEvents, year }) => {
    const { t } = useTranslation();

    const events = unsortedEvents.nodes.sort((a, b) => new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime());

    //get monday of this week
    const dateWeekEvent = new Date(events[0].timeStart)
    const monday = new Date(dateWeekEvent.getFullYear(), dateWeekEvent.getMonth(), dateWeekEvent.getDate() - dateWeekEvent.getDay() + 1, dateWeekEvent.getHours(), dateWeekEvent.getMinutes());
    const startDate = monday;


    const [isDayView, setIsDayView] = React.useState(true);
    const [selectedDay, setSelectedDay] = React.useState(0);

    React.useEffect(() => {
        // Function to check and set the hash value
        const checkHashAndSetSelectedDay = () => {
          const hash = window.location.hash.replace('#', ''); // Remove the '#' from the hash
          const hashValue = parseInt(hash, 10); // Convert the hash to an integer
    
          // Check if the hash value is between 0 and 5
          if (!isNaN(hashValue) && hashValue >= 0 && hashValue <= 5) {
            setSelectedDay(hashValue);
          }
        };
    
        // Call the function to check and set the hash value when the component mounts
        checkHashAndSetSelectedDay();
    
        // Add an event listener to listen for hash changes
        window.addEventListener('hashchange', checkHashAndSetSelectedDay);
    
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('hashchange', checkHashAndSetSelectedDay);
        };
      }, []); // Empty dependency array means this effect runs only once when the component mounts
    

    const setWeekDay = (day) => {
        setSelectedDay(day);
        setIsDayView(true);
    }
    // add 6 days to the start date
    const daysInAWeek = 6;
    //get monday of this week
    const lastDateWeekEvent = new Date(events.at(-1).timeEnd)
    const endDate = new Date(monday.getFullYear(), monday.getMonth(), (monday.getDate() - monday.getDay() + daysInAWeek), lastDateWeekEvent.getHours(), lastDateWeekEvent.getMinutes());



    //flat array of typevent in event
    var allTypesEvents = []
    //flat array of routesName in event
    var allRoutesEvents = []
    for (let index = 0; index < events.length; index++) {
        allTypesEvents = allTypesEvents.concat(events[index].typeEvent.split(",").map(type => type.trim()))
        allRoutesEvents = allRoutesEvents.concat(events[index].routeName.split(",").map(route => route.trim()))
    }
    const typeEvent = [...new Set(allTypesEvents.sort())];
    const routeName = [...new Set(allRoutesEvents.sort())];
    const places = [...new Set(events.map(event => event.locationName ?? "Non Specifié").sort())];
    const category = {
        typeEvent,
        routeName,
        places
    }
    const [selectedFilter, setSelectedFilter] = React.useState(category);
    const eventFiltered = events.filter(event => {
        return selectedFilter.typeEvent.some(item => event.typeEvent.includes(item)) &&
            selectedFilter.routeName.some(item => event.routeName.includes(item)) &&
            selectedFilter.places.includes(event.locationName ?? "Non Specifié")
    })

    return (
        <div className="container mt-12">
            <h2>
                {t("title_events", { year: year })}
            </h2>
            <div className="p-4">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
                        <div className="flex items-center">
                            <FilterListOutlined fontSize="large"></FilterListOutlined>
                            <Typography fontSize="lg">Filtre</Typography>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <FilterEvent category={category} selectedFilter={selectedFilter} setFilter={setSelectedFilter}></FilterEvent>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="lg:hidden">
                <DateView isDayView={isDayView} startDate={startDate} endDate={endDate} daysInAWeek={daysInAWeek} setIsDayView={setIsDayView} selectedDay={selectedDay} setSelectedDay={setSelectedDay}></DateView>

                <EventList events={eventFiltered} startDate={startDate} selectedDay={selectedDay} multiEventBefore></EventList>
            </div>

            <div className="hidden lg:block">
                <EventList events={eventFiltered} startDate={startDate} ></EventList>
            </div>
        </div>
    )
}
export default EditionEvents;
