import * as React from "react"
import PreviewArticleList from "./article/preview-article-list"
import TitlePageComponent from "./title-page"
import LayoutSENR from "./layout/layout-senr"
import { graphql } from 'gatsby'
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import SEOComponent from "./seo"
import { Pagination } from "@mui/material"

import EditionEvents from "./edition-event"

const EditionPage = ({ data, pageContext }) => {
  const siteContent = data.siteContent
  const editoEditionContent = data.editoEditionContent
  const { t } = useTranslation();

  const { navigate} = useI18next();
  const handleChange = (event, value) => {
    if(value === 1) value = ""
    navigate(`/edition/${pageContext.year}/${value}`);
  };

  return (
    <main>
      <LayoutSENR siteContent={siteContent}>
        <SEOComponent title={t("menu_edition", { year: pageContext.year })} />
        <TitlePageComponent title={t("menu_edition", { year: pageContext.year })} description={editoEditionContent != null ? editoEditionContent.description:""}></TitlePageComponent>
        <PreviewArticleList articles={data.editionArticles.nodes}></PreviewArticleList>
        <div className="flex flex-col container items-center">
        {
          pageContext.numberOfPages > 1 &&
            <Pagination count={pageContext.numberOfPages } page={pageContext.pageNumber +1} onChange={handleChange} size="large" color="secondary" />
        }
        
          </div>

        {data.events.nodes.length > 0 && siteContent.programYear != pageContext.year &&
          <EditionEvents events={data.events} year={pageContext.year} />
        }

      </LayoutSENR>
    </main>
  )
}
export default EditionPage;

export const query = graphql`
  query($language: String!, , $skip: Int!, $limit: Int!, $year : String) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent(language: {eq: $language}) {
      editionYear
      programYear
      edition {
        description
      }
      language
      cookieURL
      personalDataURL
      banner
      linkPass
      dateStartWeek
      hourEnd
      hourStart
    }
    editoEditionContent(language: {eq: $language}, year: {eq: $year}) {
      description
      year
      language
    }
    editionArticles: allArticle(skip: $skip 
      limit: $limit,
      filter: {typeArticle: {eq: ressources},
      language: {eq: $language},
      categories: {in: [$year]}}) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        categories
        tags
      }
    
    }
    events: allEvent(filter:{language: {eq: $language},
      year: {eq: $year}}) {
        nodes {
          timeStart
          timeEnd
          typeEvent
          routeName
          idLiferay
          slug
          locationName
          isSeveralDate
          typePublic
          typeParcours
          year
          title
          advices
        }
      }
  }
`